// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@media (hover: hover) {
    a {
        &:hover {
            color: $accent-color;
        }
    }
}
input[type="text"],
input[type="button"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
a[type="button"] {
    -webkit-appearance: none;
}
.text-color-theme {
    color: $accent-color;
}
.text-medium-gray {
    color: $medium-gray;
}
.c-pointer {
    cursor: pointer;
}
.background-gray {
    background-color: #f5f5f5;
}
.link-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: $accent-color;
    text-decoration: underline;
}
.hover12 figure {
    background: $accent-color;
    border: $round-border;
    margin: 0 0 0;
}
.hover12 figure img {
    opacity: 1;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.hover12 figure:hover img {
    opacity: 0.5;
}
.react-tel-input {
    .form-control {
        width: 100% !important;
        border-radius: 2px !important;
        height: inherit;
    }
    .form-control.is-invalid,
    .was-validated .form-control:invalid {
        border-color: #dc3545;
    }
}
.tnC-content {
    padding: 10px 25px;
    height: 420px;
    overflow: auto;
    ol {
        padding-inline-start: 18px;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        padding: 10px 10px;
    }
}
.slider-arrow {
    position: absolute;
    z-index: 5;
    top: 18%;
    padding: 13px;
    cursor: pointer;
    fill: #4c4c4c;

    svg {
        width: 20px;
        height: 20px;
        position: relative;
    }

    &.left {
        left: 35px;
        svg {
            left: -1px;
        }
    }
    &.right {
        right: 35px;
        svg {
            right: -1px;
        }
    }

    @media (max-width: 579px) {
        &.left {
            left: 20px;
        }
        &.right {
            right: 20px;
        }
    }
}

button:focus,
.btn.focus,
.btn:focus {
    &.btn {
        outline: none;
        box-shadow: none;
    }
}

.load-more-btn {
    outline: none !important;
    color: $accent-color;
    display: inline-flex;
    background-color: #ffffff;
    padding: 6px 20px;
    border: 1px solid $card-border-color;
    align-items: center;
    transition: all $local-transition-duration ease-in-out;

    svg {
        fill: $accent-color;
        margin-right: 10px;
    }

    &:hover {
        color: #ffffff;
        display: inline-flex;
        background-color: $accent-color;
        svg {
            fill: #ffffff;
        }
    }
}
.round-label {
    background: #808080;
    color: #fff;
    width: 100px;
    border-radius: 13px;
    height: auto;
    font-size: 13px;
    padding: 3px;
    text-align: center;
    margin-left: auto;
}
.light-background-section {
    background-color: $background-gray;
    padding: 80px 15px;
    @media (max-width: 576px) {
        padding-top: 20px;
    }
}
.title1 .title-inner1 {
    font-size: 23px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    text-transform: none;
    color: #003128;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    @media (max-width: 580px) {
        font-size: 21px;
    }
}
.title1 .title-inner1:after {
    position: absolute;
    height: 5px;
    width: 70px;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
}
/** for mobile **/
@media (max-width: 576px) {
    .categories-img {
        padding: 10px;
    }
    .categories-btn {
        padding-top: 5px;
        padding-bottom: 5px;
        min-width: 120px;
        height: 35px;
        font-size: 11px;
    }
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

/** for tablet **/
@media (min-width: 641px) and (max-width: 1024px) {
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

.home-category-box {
    position: relative;
    height: 100%;
    margin-bottom: 15px;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .title-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 20px;
    }
}

.slider-arrow-circle {
    position: absolute;
    z-index: 5;
    top: 30px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.6;
    padding: 8px;
    cursor: pointer;
    fill: #393b3b;

    svg {
        width: 13px;
        height: 13px;
        position: relative;
    }

    &.left {
        left: -15px;
        box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2);
        svg {
            left: -1px;
        }
    }
    &.right {
        right: -15px;
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
        svg {
            right: -1px;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.vproduct-section-2 .container {
    @media (max-width: 768px) {
        max-width: 100%;
    }
}
.vproduct-section-2 {
    background-color: #fff;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 10px 50px 10px;
    @media (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 580px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.vbrands-section {
    padding-bottom: 60px;

    @media (max-width: 768px) {
        padding-bottom: 40px;
    }
    @media (max-width: 580px) {
        padding-bottom: 20px;
    }
}
.vbrands-section .container {
    padding: 30px;
    @media (max-width: 1200px) {
        padding: 0;
    }
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 30px;
    }
    @media (max-width: 580px) {
        max-width: 100%;
        padding: 20px;
    }
    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
}
.vproduct-section-3 {
    display: flex;
    justify-content: center;
    padding: 80px 130px;
    @media (max-width: 1560px) {
        padding: 50px 0;
    }
    @media (max-width: 1200px) {
        padding: 30px 0;
    }
    @media (max-width: 768px) {
        padding: 20px;
    }
    @media (max-width: 580px) {
        padding: 0px;
    }
}
.vproduct-section-3 .category-box {
    background: #fafafa;
    width: calc(25% - 10px);
    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
        width: calc(25% - 10px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        width: calc(33.33333% - 10px);
    }
    @media (max-width: breakpoint(sm-end)) {
        width: calc(50% - 10px);
    }
    &.homepage {
        background: $homepage-dark-grey;
    }
}
.vproduct-section-3 .category-box,
.vbrands-section .brand-box {
    // border: 1px solid #e8e8e8;
    margin: 0 5px;
    margin-bottom: 25px;
    transition: 0.3s;
    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
    @media (max-width: 580px) {
        margin-bottom: 20px;
    }
}
.vproduct-section-3 .category-box:hover,
.vbrands-section .brand-box:hover {
    // box-shadow: 0 0 12px 4px #bfbfbf;
    box-shadow: 0 0 12px 4px #353535;
    @media (max-width: 1200px) {
        box-shadow: 0 0 8px 2px #353535;
    }
}
.category-box .img-wrapper {
    position: relative;
    height: 200px;
    width: 100%;
    // border-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    @media (max-width: 480px) {
        height: 170px;
    }
}
.brands-box {
    padding: 5px 10px;
    .img-wrapper {
        height: 85px;
        img {
            object-fit: contain;
        }
    }
}
.img-wrapper {
    position: relative;
    height: 240px;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media (max-width: 1200px) {
        height: 220px;
    }
    @media (max-width: 580px) {
        height: 180px;
    }
}
.vbrands-section .item-detail {
    text-align: center;
    padding: 5px;
    a {
        color: #2e2e2e;
    }
}
.vproduct-section-3 .item-detail {
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    color: #7a7a7a;
    transition: all 0.5s ease;

    a {
        color: #2e2e2e;
        overflow: hidden;
        flex-grow: 1;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &.homepage {
        a {
            color: $accent-color;
        }
    }
}
.banner-container {
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0px;
    }
}
.no-result-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infinite-scroll-component {
    overflow: hidden !important;
}
.wyswyg_title {
    text-align: center;
    padding-top: 30px;
}
.wyswyg-wrapper {
    padding: 60px 20px;
}
.wyswyg-section {
    padding: 40px 10px;
}
.slick-track {
    margin-left: 0;
}
.section-title {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 567px) {
        font-size: 23px;
    }

    &.homepage {
        color: $accent-color;
    }
}

.password-eye {
    cursor: pointer;
    float: right;
    margin-right: 6px;
    margin-top: -26px;
    position: relative;
    z-index: 2;
}
.event-banner {
    img {
        max-height: 400px;
        object-fit: contain;
        // width: 100%;
    }
}
.rating-star {
    color: #ffa200;
    &.small {
        font-size: 5px;
    }
}
.membership-card {
    margin-bottom: 15px;
    padding: 25px 15px;
    border-color: "lightslategray";

    .benefit-list {
        font-size: 15px;
        margin-inline-start: -5px;
    }
    .progress {
        height: 10px;
    }
}
.svg-icon {
    width: 200px;
    svg {
        height: 200px;
        width: 100%;
        fill: $accent-color;
        color: $accent-color;
    }
    @media (max-width: 480px) {
        width: auto;
        svg {
            height: auto;
        }
    }
}
.media-view-span {
    color: $accent-color;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.homepage-slogan {
    color: $block-features-icon-color;
    text-align: center;
    padding: 20px 0px;
}

.video-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1200px) {
        max-width: 1240px;
    }
}

.video-title {
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    color: #7a7a7a;
    transition: all 0.5s ease;

    h6 {
        color: $accent-color;
    }
}
